@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Benguiat_Normal;
    src: url(../assets/fonts/Benguiat_Normal.ttf);
}
@font-face {
    font-family: Benguiat_Bold;
    src: url(../assets/fonts/Benguiat_Bold.ttf);
}

* {
    font-family: Benguiat_Normal;
    overflow-x: hidden;
}

@layer base {
    .center-X {
        @apply flex justify-center;
    }
    .center-Y {
        @apply flex items-center;
    }
    .centers {
        @apply flex items-center justify-center;
    }
}

.w-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
}
.w-container iframe {
    position: absolute;
    width: 100%;
    height: 100%;
}
@media (min-width: 1024px) {
    .sportradar-container {
        width: 100%;
        height: 0;
        padding-bottom: 79.25%;
        position: relative;
    }
}

.slideTrack {
    animation: scroll 30s linear infinite;
    display: flex;
    width: calc(239.31px * 16);
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-239.31px * 8));
    }
}
